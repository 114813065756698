const networkConfig = {
  keycloakExternalIDP: 'simeio-uat',
  keycloakBaseUrl: 'https://iam.myhealth.gnb-doh.vsfcloud.com/auth/',
  patientDataUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/patient/',
  medicationUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/medication/',
  immunizationUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/immunization/',
  observationUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticReport/',
  diagnosticReportUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticReport/',
  imagingUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticImagingReport/',
  alertUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/alert/',
  redirectUri: 'https://myhealth-web.myhealth.gnb-doh.vsfcloud.com/',
  loginUrl: 'https://myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/authentication/login',
  authorizeUrl: 'https://access.id.gnb.ca/as/authorization.oauth2',
  clientId: 'MHNBPHRCLIENT',
  salesforcePortal: 'https://myhealth.gnb.ca/s/',
  loginProviderType: 'myhealth_broker_prod',
};

export default networkConfig;
